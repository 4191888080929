export const CustomerReview = [
    {
        id: 1003, product: "abeytu-naturals-cv-well-immuno-boosting-tincture-single ", review: [
            { date: "19-09-21", title: "Feel better ", comment: "I started this product 1.5 months ago, before I started it, my hot flashes were miserable and of course wasn't sleeping. 9 days after starting this the hot flashes were gone and my sleep is amazing. I have more energy then before and would highly recommend this product to anyone looking to feel better This is the real deal. Thank you for such an amazing product. ", rating: 5, verifiedUser: "ok", name: "Tracee Batt ", email: " traceey73@gmail.com" },
            { date: "19-09-21 ", title: "outstanding product ", comment: "Outstanding Product- use it every day! ", rating: 5, verifiedUser: "ok", name: "Jeff Bos ", email: " jabco1@mac.com" },
            { date: "19-09-21", title: " A fantastic product", comment: " Been using this product for a few days now so far I see a dramatic change in my facial appearance my face has a glow. I do sleep well with this product as well cant wait to see what happens after using this product for a month.", rating: 5, verifiedUser: "ok", name: " Ellsa R.", email: " ellsaracct@gmail.com" },
            { date: "21-09-21 ", title: " I give this product 5 stars", comment: "Ive never been one to write a review for a product, until now. This one definitely deserves a 5 star rating.  I started using this tincture twice a day, 13 days ago and Im already feeling better.  My anxiety is much lower, Im sleeping so much better, I had some previous gut issues and those have been relieved.  Im seeing some good things on the outside as well.  Its true that if you feel good on the inside, youll look good on the outside.  This is an amazing product and Id highly recommend it to everyone.  Thank you for creating this liquid gold. ", rating: 5, verifiedUser: "ok", name: " Robyn Rosenbaum", email: " robyn@910designassociates.com" },
            { date: "09-11-21", title: " It is brighter on my face and one sunspot has faded", comment: "I am an RN (for 44years)  and research any product before taking it or recommending.  I spoke to the creator at the Arizona Women's Expo in Oct 2021 and was interested to try this product.  I have been taking it for 4 weeks and see a difference in my facial skin- brighter and one sun spot   has faded. With not going out of the house as much recently,  I haven't worn much makeup and my skin looks very good, a difference by taking this product.  I just look and feel better, but, I was healthy before taking this product. I am date driven and need to see differences in using a product.  I do feel this is worth trying! I am getting it again for several months before making a final conclusions for myself.  Every person is affected differently by medications, foods, supplements, so only YOU can feel if this product works and in my opinion it is worth trying. ", rating: 5, verifiedUser: "ok", name: "Mary Beth ", email: " marybeth650@gmail.com" },
            { date: "15-11-21 ", title: " Definitely supporting this product", comment: "I have been using the CV Well Tincture for several months due to its recognizable micronutrient contents. As a physician, I highly recommend this product as an all in one solution to immune system homeostasis and prevention. Home run with this one. I am happy to throw my support behind this product. Congrats! ", rating: 5, verifiedUser: "ok", name: "Sterling Simpson MSc, MD ", email: "ssimpson@gmail.com " },
            { date: "10-01-2022", title: " This is what I call magic sauce.", comment: " I call this magic sauce. I use this for a whole plethora of things, including sunburn and it's simply amazing! And it helps that the company's CEO is a genuine, heartfelt human being who truly wants to help people heal from the inside out! ", rating: 5, verifiedUser: "ok", name: "Lacee Steele ", email: " laceesteele08@gmail.com" },
            { date: "07-04-22 ", title: "Awesome Abeytu´&reg; ! ", comment: "This tincture makes a noticeable difference.  We've got several family members taking it.  Feels great! ", rating: 5, verifiedUser: "ok", name: "Carol Moore ", email: "bebeepcarol@live.com" },
            { date: "08-04-22 ", title: " bebeepcarol@live.com", comment: " I love the products from Abeytu, not just the Tincture, but also the Rejuvenation Cream. Everytime after I have used the products, my face looks like a Baby face, smooth and flawless! However, I still have some troubles with some bumbs, and am not getting the desired results as quick as I was hoping for, but I am sure I am on the right way with these wonderful products. Thank you!", rating: 5, verifiedUser: "ok", name: "Alexandra Niklaus ", email: "alexandra.niklaus@sportfieldteam.com " },
            { date: "11-04-22 ", title: "Looking forward to being healthier ", comment: "Amazing products… all natural from plants!  So thankful for the zoom seminar so I could learn from the Q & A…this is very exciting to learn of ingredients and I can do my own research.  Which Maria walked us through!   I have already had 4 close friends order their own after watching the zoom or Sasha interview", rating: 5, verifiedUser: "ok", name: "Judy Kanthack ", email: " svheyjude2@yahoo.com" },
            { date: "19-04-22 ", title: "Tincture, face cream, lip balm, intimate cream🌟🌟🌟🌟🌟", comment: " I cannot say enough about how absolutely next level, pure and aligned these products are; especially for me because I only use earth medicines…Abeytú Natuals is a company of new Earth with the power of elevated dimensional remedies (medicines) that notably realign the body as it was originally intended💫 …much gratitude for bringing this through at this time🙏", rating: 5, verifiedUser: "ok", name: "Evelyn Guadalupe ", email: "lovefromtiel@protonmail.com " },
            { date: "20-04-22 ", title: "Need more ", comment: "Very nourishing ", rating: 5, verifiedUser: "ok", name: "Victoria Doney ", email: "victoriadoney@gmail.com " },
            { date: "25-04-22 ", title: "Produkt not received. ", comment: "I have not received the produkt yet. Hopefully it will turn up. ", rating: 5, verifiedUser: "not-yet", name: "Zoe Paikjær", email: " zoegrg@gmail.com" },
            { date: "25-04-22 ", title: "Please help save the earth ", comment: "I think your product is nice. Although I can’t really see any differences. I would really like to put in a request to have an option or completely change your packaging. It is very wasteful. I dont want packaging that goes into the earth. Even if it might be recyclable. Its wasteful. I would just like to receive my bottle safely and thats it. Im sure there is another way to do it. You ship them in a box already! Its a bit over kill with all the packaging. Thank you for your consideration to help reduce waste that is unnecessary and helping to keep Pachamama clean. ", rating: 5, verifiedUser: "not-yet", name: " Shaundra Hyre", email: "shaundra@mac.com " },
            { date: "26-04-22 ", title: "19 Day Review ", comment: " My hair and nails seem to be growing better and I have more energy. ", rating: 5, verifiedUser: "ok", name: "Jerrel Johnson ", email: " dkjj1969@gmail.com" },
            { date: "26-04-22 ", title: " Great!", comment: "I use this tincture morning and night. If I feel like I’m headed under the weather, I take five drops more in my symptoms go away. ", rating: 5, verifiedUser: "ok", name: "Sherry Sandler ", email: "ssherry64@yahoo.com" },
            { date: " 28-04-22", title: "Sick ", comment: " I  haven’t gotten sick in 3 years since I’ve been taking it.  So its working or all the vitamins I take are doing the trick.  Thank you", rating: 4, verifiedUser: "ok", name: "Pamela Hunter", email: " jjhunter5@cox.net" },
            { date: "30-04-22 ", title: " loving it", comment: " I do like the products very much. Especially the rejuvenation cream and the wellness serum. I have a LOT of new growth in my hair. I need all I can get! Hahaha. I am giving the products a 5 rating but if I had to rate the beautiful packaging, I would give it a 1. A reason I do like the packaging is that I use the very sturdy serum box for other things. However, every time I receive a shipment of it, I honestly cannot believe the money that must go into the packaging. I would be satisfied with the serum bottle wrapped in tape at the top and the entire bottle wrapped in some protective bubble wrap and have the savings passed on to me, the customer. Nonetheless, I do love the products I use, have faith in the creators at Abeytu and wish everyone much success!", rating: 5, verifiedUser: "ok", name: " Sacha TL", email: " intreatmentlimited@yahoo.co.uk" },
            { date: "04-05-22 ", title: " I've ordered multiple times", comment: " I do like the products very much. Especially the rejuvenation cream and the wellness serum. I have a LOT of new growth in my hair. I need all I can get! Hahaha. I am giving the products a 5 rating but if I had to rate the beautiful packaging, I would give it a 1. A reason I do like the packaging is that I use the very sturdy serum box for other things. However, every time I receive a shipment of it, I honestly cannot believe the money that must go into the packaging. I would be satisfied with the serum bottle wrapped in tape at the top and the entire bottle wrapped in some protective bubble wrap and have the savings passed on to me, the customer. Nonetheless, I do love the products I use, have faith in the creators at Abeytu and wish everyone much success!", rating: 5, verifiedUser: "ok", name: "CC ", email: "ccastro@dc.rr.com " },
            { date: "07-05-22 ", title: " Tincture", comment: "Something is working.  I wonder my combo is doing to the arthritis and lack of cartilage in my shoulder. They feel much better. ", rating: 5, verifiedUser: "ok", name: "Heather Plank", email: "hplankus@gmail.com" },
            { date: "10-05-22 ", title: "This benefited my body 100% ", comment: " I picked the bottle up, and muscle testing it found it to be extremely strong, and that it would benefit my body 100% !  I am passionate about your/theseAbeytu´&reg; products.  Already in 5 weeks using the rejuvenation cream on my face 2x daily, and the tincture - my skin feels and looks different.  And YES !  I took pictures in the beginning, and will share when it's a quite noticeable change Also, I work with natural and energy healings... and give detoxing footbaths.   My footbath machine comes from Bremen Health Clinic, Bremen, Ga.  and is a very professional and expensive machine.  Since about 2008, I have been giving these footbaths to others and on myself and my husband = therefore I KNOW what the water looks like especially for ourselves, over these many years.*. After using the tincture about 3 or 4 weeks, my footbath water was Very Different.  And it was really stinky.......never before in all these years has it smelled of anything.  This is evidence to me/us that the tincture (the only thing different we have been doing) REALLY REALLY pulls toxins out, and gets into/to the cellular levels.  Thanking you and Dr Emma for using your heart and soul, for bringing in the highest intentions, and Divine Wisdom for natural, plant based products.  I am onboard for the journey  Sending Sooo many Blessings ", rating: 5, verifiedUser: "ok", name: "Judy Kanthack ", email: " svheyjude5@yahoo.com" },
            { date: " 18-05-22", title: "I wish the bottle was bigger! ☺ ", comment: " I love this product so much I hate running out of it!!  ☺️☺", rating: 5, verifiedUser: "ok", name: "Rose McClintick ", email: "dovehrose@yahoo.com " },
            { date: "21-05-22 ", title: " WOW", comment: " I have been taking supplements and superfoods for year and years. Since 2015, I have had a myriad of health issues that have been considered life threatening.  The doctors haven't been able to assist and I have been taking some medications to slow down any further deteriorations and complications. Since taking the CV Well Immuno-Boosting Tincture, my lungs are clearing up and I am beginning to take fuller breaths.  I can climb the stairs at home without stopping to catch my breath half way.If this is the efficacy of this brilliant and Divinely created product after only 2 weeks, I am super excited to experience further improvement to my health.I AM SO GRATEFUL. With Love, Helen", rating: 5, verifiedUser: "not-yet", name: " HELEN SCHEINER", email: "helen.scheiner@yahoo.com " },
            { date: "26-05-22 ", title: " ", comment: " Abeytu´&reg; Naturals CV Well Immuno – Boosting Tincture", rating: 5, verifiedUser: "not-yet", name: "Harry Grace IV ", email: " hagrace4@gmail.com" },
            { date: "26-05-22 ", title: "Trusting ", comment: " I trust what I have read and heard about the tincture and I have not gotten sick.  Based on that I have to assume it is working.  I dont care for the taste  but who cares if it works.  I dont like the application because it doesn’t all come out of the dropper and I feel like I’m not getting it all.   All in all something is working.  Thank you. ", rating: 5, verifiedUser: "ok", name: " Linda Potter", email: " sunflower03@windstream.net" },
            { date: "27-05-22 ", title: "Amazing Product ", comment: "I have never felt so energized! Thank you for this amazing product. ", rating: 5, verifiedUser: "ok", name: " Donna Seico", email: "dseico@optonline.net " },
            { date: "23-06-22 ", title: "Magic Potion ", comment: "Turned my world around, from chest pain, sleeplessness and nervousness, to pain free, and sleeping well.. Thank you for this natural medicine.. ", rating: 5, verifiedUser: "ok", name: "Jenny Louise van van Pellekaan ", email: " jenny_irv71@yahoo.com.au" },
            { date: "27-06-22 ", title: "So Far so Good ", comment: "This is forth month and hair seems to be growing. ", rating: 5, verifiedUser: "ok", name: "Lee-Ann  ", email: " lenbda@gmail.com" },
            { date: "08-07-22 ", title: " Cv booster", comment: " Amazing!", rating: 5, verifiedUser: "ok", name: "Gina Guida ", email: " ginagrl007@gmail.com" },
            { date: "11-07-22", title: " ", comment: "Excellent ", rating: 5, verifiedUser: "ok", name: "Jo Lynn Hawthorne ", email: " johawthorne22@gmail.com" },
            { date: "02-08-22 ", title: "Brilliant Products and Customer Service ", comment: "I am really impressed by the results I am seeing within 1 month of usage. It's MAGIC! ", rating: 5, verifiedUser: "ok", name: " Belinda Connor", email: " bjordann77@gmail.com" },
            { date: "22-08-22 ", title: " Replaces 6 Supplements!", comment: " My everyday go to for immune support. I no longer put a handful of pills through my stomach or liver. I’ve got all I need in a safe & convenient sublingual", rating: 5, verifiedUser: "ok", name: " Maria Crisler", email: "maria@abeytu.us " },
            { date: "20-09-22 ", title: "Delighted With Results ", comment: "Your amazing product, CV Well Immunity Booster, resolved a persistent tooth infection that nothing else touched. And while that was enough to draw me to your company and its products, your customer service is very responsive, and I love the informative emails you send. Thank you and keep up the good work! ", rating: 5, verifiedUser: "ok", name: " Robin", email: " rvweiner@bellsouth.net" },
            { date: "05-10-22 ", title: " Abeytu´&reg; natural well immunity", comment: " When I got introduced to this immune booster I was very very sick no one knows why but this immune booster probably saved my life literally I’m still taking it", rating: 5, verifiedUser: "ok", name: " creta johnson", email: " creta.johnson08@yahoo.com" },
            { date: "23-10-22 ", title: "No calling out sick ", comment: "I have been taking the immunity booster for quite some time now and have to honestly say it has kept me from calling out sick from work as much as I used to. ", rating: 5, verifiedUser: "ok", name: " Marilyn Rios", email: "mysistereve60@gmail.com " },
            { date: "27-10-22 ", title: "Amazing ", comment: " Love the nutrients the tincture gives my body!!", rating: 5, verifiedUser: "ok", name: " Christi Smart", email: "christiburnett@yahoo.com " },
            { date: "28-10-22 ", title: " Cv well immunity  booster", comment: " Since I’ve started taking the tincture, I have more energy, less body aches/stiffness after exercising and more stamina. There isn’t anything else that I’ve done differently.  I believe it’s the tincture  that’s made a positive difference in me.", rating: 5, verifiedUser: "ok", name: " j. almas", email: "gemograd08@yahoo.com " },
            { date: "28-10-22 ", title: " Awesome ! ", comment: "Just what my body needs! ", rating: 5, verifiedUser: "ok", name: "Jacqui Adams ", email: "jacqui.adams@hotmail.com " },
            { date: "18-11-22 ", title: "  ", comment: "Abeytu´&reg; Naturals CV Well Immunity Booster ", rating: 5, verifiedUser: "ok", name: "Jacklyn Henry ", email: "jaysheart.henry@yahoo.com" },
            { date: "25-11-22 ", title: "  ", comment: "Abeytu´&reg; Naturals CV Well Immunity Booster ", rating: 5, verifiedUser: "ok", name: "Kandi Arnold ", email: "arnold.kandijo@gmail.com" },
            { date: "04-12-22 ", title: "Tincture ", comment: "I have been on this for almost a month. I have noticed increased energy and overall a feeling of well being. I have been around a lot of sick people lately and I haven't gotten sick so it definitely has boosted my immune system! Thank you. ", rating: 5, verifiedUser: "ok", name: "Lorye McLeod ", email: " mcleodl@hotmail.com" },
            { date: "05-12-22", title: "CV Well ", comment: "Excellent product, works well...felt I had a cold coming on, dosed up and gone by that afternoon ! ", rating: 5, verifiedUser: "ok", name: "Maggie Hill ", email: "bio.energywisdom@gmail.com " },
            { date: "16-01-23", title: " Great for hands too!", comment: " Using not only on my face and throat AM and PM, but also for dry winter hands.", rating: 5, verifiedUser: "ok", name: "CV Well ", email: " " },
            { date: "12-02-23 ", title: " Good product", comment: " ", rating: 4, verifiedUser: "ok", name: "Amy Norris ", email: " emariya2000@hotmail.com" },
            { date: "16-03-23 ", title: "Energy off of levothyroxine ", comment: " ", rating: 5, verifiedUser: "ok", name: "Teresa LeBlanc ", email: " tleblancfamily@yahoo.com" },
            { date: "04-04-23 ", title: "Love!! ", comment: " ", rating: 5, verifiedUser: "ok", name: " Tina Nelson", email: " tmarienels1109@gmail.com" },
            { date: "08-05-23 ", title: " Abeytu Tincture", comment: " ", rating: 5, verifiedUser: "ok", name: "Shehnaz Soni ", email: "ssoni1015@gmail.com " },
            { date: "11-05-23 ", title: "Love products", comment: " ", rating: 5, verifiedUser: "not-yet", name: " Linda Jones", email: " lindajones.lj59@gmail.com" },
            {
                date: "15-06-23 ", title: "Put it on EVERYTHING!!", comment: `
                The sauce is absolutely magical. I put it on every ailment I can think of! 
                Sunburn, scrapes & bruises, ingrown hairs, rash, and any other minor thing! I also use it on my face every night before bed. It’s absolutely magical and the benefits are great whether topical or sublingual! ♥️
            `, rating: 5, verifiedUser: "ok", name: "Lacee", email: ""
            },

        ]
    },

    {
        id: 1011, product: "abeytu-naturals-facial-breakout-cream", review: [
            { date: "15-12-22 ", title: " A wonder!!", comment: " Pure nature at its best!", rating: "5", verifiedUser: "ok", name: " Jacqui Adams", email: "jacqui.adams@hotmail.com " }
        ]
    },

    {
        id: 1007, product: "abeytu-naturals-fungal-solutions-kit", review: [
            { date: "14-06-22", title: " ", comment: "First this product is not from Global Healing! That is the option for the Review Title.  Regarding the product I already sent a picture regarding it.  The container received was half full. ", rating: 2, verifiedUser: "not-yet", name: "Nancy Duncan ", email: " nannerbear@sbcglobal.net " },
            { date: "05-07-22 ", title: "Has not arrived yet ", comment: "I look forward to receive my products! Post slow to Australia ", rating: "5", verifiedUser: " not-yet", name: " Hunna Ovcar", email: " hunnashearth@gmail.com" },
            { date: "09-07-22 ", title: " 5star", comment: " quite amazing really", rating: "5", verifiedUser: "ok", name: " Sacha TL", email: "intreatmentlimited@yahoo.co.uk " },
            { date: "03-08-22 ", title: " Superb Product", comment: "I think it a great product and I already see progress. ", rating: "5", verifiedUser: "ok", name: " Keith Stewart", email: "klstew848@aol.com " },
            { date: "16-08-22", title: " Callus free", comment: "Awesome product/repeat customer. My feet are so soft. This cream allows me to go three times as long without having to file my heals. Love, love this product! ", rating: "5 ", verifiedUser: "ok", name: " Elizabeth Smith", email: " olemissrbls@protonmail.com" },
            { date: "29-08-22 ", title: "Amazing ", comment: "This product did an amazing job. I've been struggling with a fungus I got from the salon 😩 for several years. Nothing worked 😪 everything was temporary, I was told by my doctor I could get medication 💊 for it but it may damage my liver. Thanks TO ABEYTU MY FEET ARE BEAUTIFUL ONCE AGAIN. NOW IM TRYING IT ON SOFT FINGER 💅 ", rating: "5", verifiedUser: "ok", name: "Jacklyn Henry ", email: "jaysheart.henry@yahoo.com " },
            { date: "19-11-22 ", title: "Hands and feet ", comment: " I love it bought some for my son too and he loves it also.", rating: "5", verifiedUser: "ok", name: " Judy Davis", email: "lovemylillies399@gmail.com " },
            { date: " 22-03-23", title: "LOVE the Fungal Solution Cream!! ", comment: "Hi. I live on an island in BC so the products only arrived a few days ago. Not 29 days ago... I'm onto my second jar of fungus cream ( thanks... BTW the second jar was full whereas, the first was 1/2 full). So far I'm very pleased! I feel it improving every day. After 20 years of foot fungus, ( tried zillions of products)  I definitely see great improvement in 4 weeks of daily 2x application. ( I'm also on a cleanse/ detox to systemically support the healing process too). I'm hoping that with 2- maybe 3 more jars, I will be free and clear of this sick foot condition, once and for all. (BTW I'm in my late 60's.) However, the lip balm so far, is a bit of a disappointment.I'm open tho! :) I've been applying it 2- 3x daily, but am not noticing much difference and my lips are still very dry. It's only been seven days though, so I'm sure it will improve. I'm taking the internal drops too, so I'm sure it will take a while to kick in. :) Thanks for the lovely and holistic , effective products! Foot photos are AFTER 2 weeks- pink feet after a swim and my single foot BEFORE treatment. It was way worse 20 years ago so I have made progress and now this is the cincher! God bless! ", rating: "5 ", verifiedUser: "ok", name: "Customer", email: "christiegracestudio@gmail.com " }
        ]
    },

    {
        id: 1009, product: "abeytu-naturals-rejuvenation-cream ", review: [
            { date: "27-03-22 ", title: "Rejuvenation creme ", comment: "I noticed brown patches disappearing after only 2 weeks. Bumps and kerritosis disappeared after 3-4 weeks. thank you. ", rating: "5", verifiedUser: "ok", name: " Joanne Scott", email: " joannecscott@gmail.com" },
            { date: "13-04-22", title: "Love the ingredients ", comment: " My good friend recommended it to me. It is a great product . I love the natural ingredients.. I use it everyday and notice a soft glow.", rating: "5", verifiedUser: "ok", name: "Elizabeth Krebs ", email: " elikrebs@hotmail.com" },
            { date: "16-04-22 ", title: " Sooo creamy and precious", comment: " Just loving this cream, it’s fluffy and the same light gold as your amazing tincture Using it morning and Ed time, my poor sun damaged facial skin seems to be pushing crusty skin out and I anticipate new softer skin will be forming next. I took close up pictures and look forward to seeing and sharing amazing changes", rating: "5", verifiedUser: "ok", name: "Judy Kanthack ", email: " svheyjude2@yahoo.com" },
            { date: "25-04-22 ", title: " Baby Skin", comment: " Love the cream, have such a smooth complex after applying and does not give me a shiny skin at all. Use it at night only for now. The smell is just incredible. It's really nice, to end the day with the treat of applying this cream on your face.", rating: "5", verifiedUser: "ok", name: "Alexandra Niklaus ", email: "alexandra.niklaus@sportfieldteam.com " },
            { date: "27-04-22", title: "Velvety soft skin ", comment: " The cream is wonderful.  Leaves skin velvety soft!", rating: "5", verifiedUser: "ok", name: "Carol Moore ", email: "bebeepcarol@live.com " },
            { date: "04-05-22 ", title: "Life changing products ", comment: "I was introduced to Maria at Abeytu and her amazing products back in January. I knew that she had put together a fabulous product just by the ingredients so not only can you get all of those ingredients in one bottle, saving a fortune on buying individually but the quality and perfect formula make it just brilliant. My immune health has been transformed and many of my clients battling against debilitating illness’s and auto immune disease have Noticeably seen their health transform within a month by taking the wellness formula. Not only that the improvement in their skin and hair has been so different. I also take the weight management formula which has helped me no end as well as the tincture with my adrenals and hormones. I would recommend all of their products it really makes a difference. Thank you! Melissa Connor UK ", rating: "5", verifiedUser: "ok", name: " Melissa Connor", email: " minaconnor42@gmail.com" },
            { date: "09-05-22", title: "Facial skin getting better ", comment: " Discoloration spots are fading, the cream feels so good on my skin - I can feel the added moisture.", rating: "5", verifiedUser: "ok", name: "Sandy Hughes ", email: "sandy@hughesdesigns.net " },
            { date: "15-05-22 ", title: " I love this cream", comment: "My complexion seems a lot smoother, and my skin feels softer – dark spots are getting lighter.  I love it. ", rating: "5", verifiedUser: "ok", name: " Robyn Perry", email: "robyn@sangrehomedecor.com " },
            { date: "18-05-22", title: " ", comment: " Abeytu´&reg; Naturals Rejuvenation Cream", rating: "5", verifiedUser: "ok", name: "Gina Guida ", email: "ginagrl007@gmail.com " },
            { date: "21-05-22", title: "Have not received ", comment: " I have not received my products yet.  So cannot give a review.", rating: "5", verifiedUser: "not-yet", name: " Kitana Kelly", email: "zanna.kelly@bigpond.com " },
            { date: "26-05-22", title: " Amazing", comment: " Fine lines under my eyes are disappearing.  My complexion is soft, smooth and vibrant.  I absolutely LOVE this product.  It is natural and does not burn if it gets in my eyes.  Incidentally, this product can be applied to the lids.  You dont get that from the toxic creams elsewhere.  Great product. Thank you.", rating: "5", verifiedUser: "ok", name: " Linda Potter", email: "sunflower03@windstream.net " },
            { date: "10-06-22 ", title: "Face cream ", comment: " I began using this rejuvenation cream several months ago and i am amazed at how rested and youthful my skin looks every morning even when I’ve had a stressful day before. ", rating: "5", verifiedUser: "ok", name: " janet almas", email: " janetalmas@hotmail.com" },
            { date: "24-06-22 ", title: "Great results ", comment: "  We live in South America and my purchases are shipped to a shipping company in US and then shipped to me.  It takes time to get to me.  I am waiting for my second purchase.  However, still have first purchase and do not use on daily basis and still great results.  When I do use my husband even notices and ask what I have done to my face.  Says I look great.  You are such a pretty bird! is his comments.  I am 76 years old and I do look younger than that age.  That is hereditary.  Without make-up, people think I am in my 60s and with light make-up, 50s.   I also get rosacea which are large red bumps on my nose.  This cream clears this up over night.  Also if I have any blemishes on my face, it clears it up.  There is a fine texture to my skin.  I LOVE THIS STUFF!!! Thanks! Alisha", rating: "5", verifiedUser: "ok", name: " AP Shipping/Alisha King ", email: "solarbirdking@aol.com " },
            { date: "26-06-22 ", title: "great Products  ", comment: "The rejuvenation cream is divine, nourishing and thick but leaves no residue at all. A little goes a long way. ", rating: "5", verifiedUser: "ok", name: "monica ", email: " mcorser@yahoo.com" },
            { date: "28-06-22 ", title: "Amazing product - I will use this forever!", comment: " I love this cream. My face feels softer and looks more youthful after a month of use. I would love to have lip balm made by Abeytu!", rating: "5", verifiedUser: "ok", name: "Cass Clayton ", email: "cassclayton@gmail.com " },
            { date: "28-06-22 ", title: " Great cream", comment: " I really like how the cream feels. Product is excellent, Although this is the second time my order has come very messy in the jar and leaking from the cap.  Seems like very little product was left for use.", rating: "54", verifiedUser: "not-yet", name: " Tehemina Bradfield", email: "tehaloha@yahoo.com " },
            { date: "24-07-22 ", title: " Cleanest most nourishing ✨✨✨✨", comment: " A must simply clean & powerful  So grateful ladies 💖", rating: "5", verifiedUser: "ok", name: " Victoria Doney", email: "victoriadoney@gmail.com " },
            { date: "23-08-22 ", title: " ", comment: "I just started it . I thought I was getting the original one I ordered. Verdict is out on this yet. Give me a couple of weeks and I will definitely let you know. ", rating: "5", verifiedUser: "ok", name: "Judy Davis ", email: "lovemylillies399@gmail.com" },
            { date: "30-08-22", title: " ", comment: " I really like the cream, this is my second time ordering. The problem I have is with the cap, it is already cracked and I will probably have to put it in another container.", rating: "5", verifiedUser: "ok", name: "Danny ", email: " haikudanny.ca@gmail.com" },
            { date: "31-08-22 ", title: "Brilliant product! ", comment: " My skin feels so much better in just 2 weeks. This product works like magic! I am definitely adding this to my skin routine.", rating: "5", verifiedUser: "ok", name: "Alexandra Dowling ", email: "info@abeytunaturals.com " },
            { date: "12-09-22 ", title: " loving it", comment: " brilliant cream, feels very natural and nourishing to the skin", rating: "5", verifiedUser: "ok", name: " Sacha TL", email: "intreatmentlimited@yahoo.co.uk " },
            { date: "26-09-22 ", title: " Excellent", comment: " Just purchased my third jar. My skin never looked healthier! Smoother texture, less skin issues….I’m 68 and it even reduced some wrinkles ….", rating: "5", verifiedUser: "ok", name: " Paula Allen", email: " pollyzoom@gmail.com" },
            { date: "03-10-22 ", title: " ", comment: "Abeytu´&reg; Naturals Rejuvenation Vitamin C Cream ", rating: "4", verifiedUser: "ok", name: " Patty Hilliard", email: " hilliard_23@hotmail.com" },
            { date: "27-10-22 ", title: " Rejuvenation Vit C cream", comment: "This is a wonderful night cream,  I feel it is keeping my skin hydrated overnight without feeling heavy at all,  my skin is soft and nourished in the morning and I believe it is softening some fine lines.   I love it!", rating: "5", verifiedUser: "ok", name: "Hunna Ovcar ", email: " hunnashearth@gmail.com" },
            { date: "15-11-22 ", title: " ", comment: " Love the creams!", rating: "5", verifiedUser: "ok", name: "Mary Cyr ", email: "mlcmlcmlc@hotmail.com " },
            { date: "01-12-22 ", title: "Love, love, love it! ", comment: "I love what this cream has done for my face so much, I wish I could apply it all over my body. My complexion has never been so bight and clear.  People really notice.  They also say I look far younger than I am. ", rating: "5", verifiedUser: "ok", name: "Justine Dorval ", email: " jdorval@westnet.com.au" },
            { date: "31-01-23 ", title: "Creamy ", comment: "My favorite face cream ever ", rating: "5", verifiedUser: "ok", name: "Valerie Wolf ", email: "valjwolf@yahoo.com " },
            { date: "10-02-23 ", title: " Amazing!!", comment: " Feels great, smells great, and heals questionably areas on my face quickly! Love  it!!!", rating: "5", verifiedUser: "ok", name: "Rose McClintick ", email: "dovehrose@yahoo.com " },
            { date: "03-03-23 ", title: " I love it", comment: "I love the way it feels and make my skin feel so soft. ", rating: "5", verifiedUser: "ok", name: " Linda Jones", email: "lindajones.lj59@gmail.com " },
            { date: "29-03-23 ", title: "Could be thicker", comment: " A++++", rating: "5", verifiedUser: "ok", name: " Cheryl Giglio", email: "chergig384@gmail.com " },
            { date: "20-04-23 ", title: "Best products I’ve ever used ", comment: "I use several products and love them all! My top favorite is the four cream. It has made a big difference for my feet and nails. I’ma customer for life! Do yourself a favor and spend the money to get yourself something that actually works. Love the night cream and the wellness gummies too… ", rating: "5", verifiedUser: "ok", name: " Cynthia L.", email: " cyntolman@gmail.com" },
        ]
    },

    {
        id: 1005, product: "abeytu-naturals-wellness-weight-management-capsules ", review: [
            { date: "06-04-22 ", title: "Caplues and drops ", comment: " These two products are working together.  I know they are healing my shoulder!  Not sure about losing the weight.", rating: "5", verifiedUser: "ok", name: "Heather Plank ", email: "hplankus@gmail.com " },
            { date: "22-04-22 ", title: "Wellness Weight Management capsules ", comment: "I’ve been using these for several weeks now and have noticed sustained good energy levels and no stiffness after hiking / exercising every day. Also little recovery time needed same day or the day after. As a result, I’m spending more time outside walking and burning more calories. ", rating: "5", verifiedUser: "ok", name: "j. almas ", email: "gemograd08@yahoo.com " },
            { date: "02-05-22 ", title: " Not hungry on Wellness Weight Management", comment: "I've not lost weight, but definitely eating less.  Just not hungry.  When I do sense hunger, and eat, it doesn't take much to satiate.  This is what I've noticed so far. ", rating: "5", verifiedUser: "ok", name: " Carol Moore", email: " bebeepcarol@live.com" },
            { date: "04-05-22 ", title: "It just feels right!! ", comment: " It hasn’t been long since I started taking the capsules, just over two weeks. At this point all I can say is that it feels right.  My nails, my fungus issues, my sleep, my mood all showing signs of improving.  I feel calmer, stress isn’t a part of my day as it can be from time to time.  I have recommended this to several people and waiting to hear how their body’s have that “feels right “ feeling.  Thank you!  Thank you for caring enough to make something that the body loves!!!", rating: "5", verifiedUser: "ok", name: "Rose McClintick ", email: "dovehrose@yahoo.com " },
            { date: "14-05-22 ", title: "Hunger Busters ", comment: " I intermittant fast every day on a 7/17 schedule or eat OMD. I used to get really hungry before allowing myself to eat.  I have found that the gnawing hunger pains no longer happen as much or as often, allowing me to fast with greater ease.", rating: "5", verifiedUser: "ok", name: "Marty Phelix ", email: " martyree@gmail.com" },
            { date: "20-05-22 ", title: "Haven't noticed anything yet ", comment: " Hi, it's only Bern a couple weeks.i haven't noticed anything yet.", rating: "3", verifiedUser: "not-yet", name: " Rita Maslansky", email: "ritamaslansky@gmail.com " },
            { date: "07-08-22 ", title: " ", comment: "Waiting to see some results been taking 2 caps daily for the last two weeks  Down 3 pounds so far ", rating: "4", verifiedUser: "ok", name: "Sara Gonzales ", email: " speedie8812@aol.com" },
            { date: "14-09-22 ", title: "Changed by life ", comment: "Been on the tincture and capsules for two weeks now, and it is amazing how much it has changed a lot of things for me. Have a ton of energy and all bodily functions have kicked in nicely! Very calming, it has changed my everyday existence! Thank you!!!", rating: "5", verifiedUser: "ok", name: " Gemina Petruzzelli", email: "gempetruzzelli@gmail.com " },
            { date: "10-04-23", title: " ", comment: "Abeytu´&reg; Naturals Wellness Essentials ", rating: "5", verifiedUser: "ok", name: "Chris Austin ", email: " delawarepilot@gmail.com" },
            { date: "01-06-23 ", title: "Fantastic ", comment: "I love the tincture and capsules! I love supporting my body with these!!! ", rating: "5", verifiedUser: "not-yet", name: "Missy Mitchell ", email: " missymmitchell@gmail.com" },
        ]
    },

    {
        id: 1001, product: "cv-well-micronutrient-infused-gummies ", review: [
            { date: "12-07-22", title: "Tasty! ", comment: " Easy to carry and delicious!", rating: "5", verifiedUser: "ok", name: "cazyjones ", email: "cazyjones@gmail.com " },
            { date: "11-08-22 ", title: "Tasty! ", comment: "They taste well and work great! Good job with this product guys! ", rating: "5", verifiedUser: "ok", name: " Alexandra Dowling", email: " info@abeytunaturals.com" },
            { date: "12-08-22 ", title: " Game Changers!", comment: " I love the gummies!  They are the perfect compliment to the tincture and capsules!  I take a half in the morning and then one and a half before bed.  I am sleeping so soundly and feel a sense of calm throughout my day!  They are delicious and have also been the perfect option for my kids - both are taking them daily.  I highly recommend and am so thankful Abeytu created this amazing product!", rating: "5", verifiedUser: "ok", name: " Beth Piazzi", email: "elizabeth.piazzi@yahoo.com " },
            { date: "23-08-22 ", title: "Gummies ", comment: "They taste great.  My energy level is up to normal again and the stiffness in my knee after exercising is greatly reduced since I’ve started taking these. ", rating: "5", verifiedUser: "ok", name: " j. almas", email: " gemograd08@yahoo.com" },
            { date: "04-10-22 ", title: "These are wonderful! ", comment: "I love these!  I take them an hour or so before bed and I am sleeping so soundly to the point I am dreaming again!  Thank you! ", rating: "5", verifiedUser: "ok", name: "Cathy Kieta ", email: "cathy.s.kieta@nm.com " },
            { date: "28-10-22 ", title: "Absolutely love the tincture and the gummies! ", comment: " I never miss a day!", rating: "5", verifiedUser: "ok", name: " Arthur Rosenbaum", email: "artrosenbaum@vandykgroup.com " },
            { date: "29-11-22 ", title: "AHHHHH-MAZING ", comment: "My whole family loves these gummies! The flavor is fantastic and it’s a great way to get the magical Abeytu sauce inside the picky ones 😉 ", rating: "5", verifiedUser: "ok", name: " Lacee", email: " laceesteele08@gmail.com" },
            { date: "09-12-22 ", title: "Love the product ", comment: " I started back on Abeytu Naturals after being diagnosed with Lyme Disease.  Great product and professional company that always answers your questions and works with you on your individual goals for the right product.  Gummies great taste.", rating: "5", verifiedUser: "ok", name: " Mary Beth RN", email: " marybeth650@gmail.com" },
            { date: "13-02-23 ", title: " Absolutely Amazing!", comment: "Best sleep ever after taking one prior to bedtime.  Skipped taking gummies several nights & knew that I never want to be out of them! ", rating: "5", verifiedUser: "ok", name: " Jeanele Snow", email: " snowjrfamily@gmail.com" },
        ]
    },
    {
        id: 1096, product: "insu_less", review: [
            { date: "12-07-23", title: "AMAZING, almost instant results ! ", comment: "I knew my lip was getting ready to have an issue, that tingling, bothersome feeling that preceeds it. Plus I had the overall feeling of discomfort/tiredness, and more. Knowing these Abeytu Naturals tinctures, I grabbed the new Insuless bottle and just dabbed some on my lower lip. I really didn’t give it anymore thought - which meant it wasn’t bothering me anymore. And now 2 days later, realizing no issue materialized. My lip is kissable. My name is Judy, and I’ve used these tinctures for 2 1/2 years now. Which means, I am sooo very pleased with them, and will be a forever customer.", rating: "5", verifiedUser: "ok", name: "Judy K ", email: "jodyk8@gmail.com " },


            { date: "10-10-23", comment: " tried InsuLess in Sport and was very impressed how high my Energy was and how fast i recoverd the next day ! It helpes me also in my daily Detox regime … very good product thanks 🙏🏼 ", rating: "5", verifiedUser: "ok", name: "Kevin R. -Germany  ", email: "kevin02germany@gmail.com " },

            { date: "19-01-25", comment: `I’m 11 months postpartum, and for the last four months I’ve had a strange circular rash on my face and hands. I thought maybe Discoid Eczema, but the dermatologist didn’t recognize it, and she prescribed steroids which I never took. The rash was so bad I avoided all holiday parties because I was embarrassed to show my face and hands. I tried every natural product I could think of to help heal - tallow, coconut oil, C60, black seed oil, iodine.. etc and nothing seemed to help. My health coach shared that the recently introduced version of InsuLess was getting amazing results. 
                I’ve been using InsuLess for two weeks now, and my face & hands are almost healed!! It’s truly a miracle product in my opinion, and I never want to be without it! Thank you, Maria & GH for your terrific product and help.
                `, rating: "5", verifiedUser: "ok", name: "Krista Kelly", email: "" },
        ]
    },
    {
        id: 1019, product: " newearth-regenesi ", review: [
            { date: "09-06-23 ", title: "Reversed urinary issues for my husband ", comment: "My husband was struggling with constant urges to urinate throughout the night - with little success of a healthy stream of urine. He would wake up 3-4 tomes a night, felt like he had to go; only to have 2-3 drops come. After 1-2 weeks of using 5 drops of Abeyt�, his sleep was not interrupted by urges to urinate, he slept through the night minus 1 time of needing to go - and when he did, it was smooth and productive. Whatever that was - is gone and he has no issue at all. He / we ...are amazed. Thank you! __", rating: "5", verifiedUser: "UTC", name: "E.W. ", email: "" },
            { date: "24-05-23 ", title: "Absolutely MARVELOUS ", comment: "Significant increase in stamina - and not only can I keep going longer but without growing hungry in ways I used to a general increase in a feeling of lightness, of simple joy, almost like a little child does the second time I took it my whole body felt like it was kissed by an angel - absolutely MARVELOUS. I'm so excited about it that I don't want to risk running out of it and having to wait due to demand that might surpass the ability to keep up.So, I'll be ordering another bottle momentarily. ", rating: "5", verifiedUser: "ok", name: "Stephen ", email: " kinsdomainer@yahoo.com" },
            { date: "30-05-23 ", title: " ", comment: "NewEarth ReGenesis ", rating: "5", verifiedUser: "not-yet", name: "Martha Salinas", email: "smartha038@gmail.com" },
            { date: "30-05-23 ", title: "Opening my heart to NewEarth ReGenesis ", comment: "I gave up coffee the day I started ReGenesis. I had a truly easy transition of not caring or having any attachment to the coffee effects, and I actually feel more vibrant and alive. I am super happy with this product and am journaling this experience. I experience more of a calm lately, so necessary during these days of imbalance! ", rating: "5", verifiedUser: "not-yet", name: "Jo Coppola ", email: "voyageofthepen@gmail.com" },
            { date: "30-05-23 ", title: "succinct title ", comment: "The product gives me grounded energy and optimism", rating: "5", verifiedUser: "not-yet", name: "neva ingalls", email: "nevafusion@gmail.com" },
            { date: "30-05-23 ", title: "Thank you -  I have received it - like packaging ", comment: "It hasn’t been long enough in  use to notice anything yet  And I am not sure what I should expect ", rating: "5", verifiedUser: "ok", name: "Tami Hubbard", email: "hubbard.tami98@gmail.com" },
            { date: "30-05-23 ", title: "Deena", comment: "I love it feel more safe bouncing around. This formula does give me energy", rating: "5", verifiedUser: "not-yet", name: "Deena Wangler", email: "deena.wangler@gmail.com " },
            { date: "30-05-23 ", title: "Rating my experience with NewEarth ReGenesis Breakthrough Formula ", comment: "There are no words to adequately describe my complete and total satisfaction with this product.  Since I’ve started taking it (about 5-6 drops sublingually before two of my three meals each day) I’m feeling myself changing in many ways, not just physically.  It’s like, I can’t even relate to who I was just 24 hours ago, so rapid is my consciousness rising.  It’s truly mind boggling!  I could go on and on about it but I’ll end this by saying that I am blown away by how much more time I am spending in higher dimensions!  I’m having such an amazing experience that I just want to be still and quiet within myself to maximize my enjoyment of my life force, of the God within me.  This process began at a much slower rate before NERG but it accelerated bigtime since I started rewarding myself with it (I think) 17 days ago.  Thank you thank you thank you to all who played a part in making this AMAZING product available for me.  God bless you and keep you prosperous, safe, and wildly successful!  A BBBBIIIIGGGG Hug!  :-)", rating: "5", verifiedUser: "not-yet", name: "Stephen Mueller", email: "kinsdomainer@yahoo.com" },
            { date: "30-05-23", title: "Feeling Centered", comment: "After the second day of taking the ReGenesis I felt more energized and mentally clearer in my day to day routines. My sleep has been much better and just a sensation of wellbeing which is really helpful with what we are all going through energetically.  I’m sure much is being helped in my body that I’m unaware of so I’m letting this amazing product do its thing with faith in the people behind the creation of it 🥰", rating: "5", verifiedUser: "not-yet", name: " Christie Lefebvre", email: " sconsetflair@comcast.net" },
            { date: "30-05-23 ", title: "New Earth ReGenesis ", comment: " I have more energy and I’ve noticed a big difference in my skin.  My facial skin is vibrant and smooth.  My friends are asking me what I’m using on my face because my skin is looking so good.  I’m thrilled!   Thank you so much for making such an outstanding health product that exceeds my expectations!", rating: "5", verifiedUser: "not-yet", name: "Earlyn Smith ", email: " lynatx5437@yahoo.com" },
            { date: "30-05-23 ", title: "Don’t know ", comment: "I have only taken it 5 days so far ", rating: "5", verifiedUser: "not-yet", name: "Jeffrey L Hall ", email: "jivatar@hotmail.com " },
            { date: "30-05-23 ", title: "Energy Power ", comment: " Since taking ReGenesis for almost two weeks now, I have noticed my energy soaring. Not much need for my usual mid afternoon naps.Can’t wait to see additional benefits with further use.", rating: "5", verifiedUser: "not-yet", name: " Sue T", email: " ladysjt@gmail.com" },
            { date: "30-05-23 ", title: " ", comment: " About a week after starting the drops I started to dream when I sleep.  I never have dreams.  I’ve been having dreams all night ever since.", rating: "5", verifiedUser: "not-yet", name: "Jeana Bray ", email: "jeanabray@gmail.com " },
            { date: "30-05-23", title: " Amazing", comment: " Great taste & easy to use", rating: "5", verifiedUser: "not-yet", name: " Jennifer Hornbuckle", email: " buckle_j@yahoo.com" },
            { date: "30-05-23 ", title: "NER ", comment: "No dought your product is excellent, the only issues I have is the applicator won’t stay together, the cap comes apart and falls on the ground. Also the marerial is so thick it sticks to the inside and ourside of the tube. Do you think I could add more olive oil to make it flow better? Thank you, Brad ", rating: "5", verifiedUser: "not-yet", name: " Brad Dutton", email: "braddutton58@gmail.com " },
            { date: "30-05-23 ", title: " ", comment: "NewEarth ReGenesis ", rating: "5", verifiedUser: "not-yet", name: "gabriela oros ", email: "smoothtransitionsgo59@yahoo.com " },
            { date: "30-05-23 ", title: "ReGenesis ", comment: " Hi I was happy yo find so far that it makes me feel awake, not in-between. What I see are more clear.  :) taking 5 dropsa day.", rating: "5", verifiedUser: "not-yet", name: " TM", email: "tmpookey1@juno.com " },
            { date: "30-05-23", title: "Extra Consciousness Clarity ", comment: "I feel a new level of calmness and being able to handle chaos.... like never before.", rating: "5", verifiedUser: "not-yet", name: "Feather Tiffany ", email: "quantumliving777@gmail.com " },
            { date: "30-05-23 ", title: " Newearht Regeneris", comment: " Beautiful bottle - easy to use - so far have been taking for about a week now - I feel good and feels like I'm shifting", rating: "5", verifiedUser: "not-yet", name: "Valorie Reed ", email: "lanomore@charter.net " },
            { date: "31-05-23", title: " ", comment: "I’m finding I have a lot more energy through out the day. I have cranky knees and doing other holistic modalities knowing they will heal but they are still cranky but not as much. I’m on vacation next week, lots of moving around so that will be a test for the cranky knees. I’ll keep you posted. Thanks for all you do. ❤️ ", rating: "5", verifiedUser: "not-yet", name: " Karen Driscoll", email: " ncbeachqueen@gmail.com" },
            { date: "31-05-23", title: "Wonderful products ", comment: " I bought some reGenesis from this company which I already love so much I have ordered more. The company let me know when to expect delivery and it was right on time. They were wonderful to deal with❤️", rating: "5", verifiedUser: "not-yet", name: "Karen Daigle ", email: "pjkarenn@aol.com " },
            { date: "31-05-23 ", title: "Never got my order. ", comment: " I am still waiting for my order.  It never came!!!!  Can you find out what happened??", rating: "5", verifiedUser: "not-yet", name: " Paula LaBerge", email: " leberge@shaw.ca" },
            { date: "31-05-23 ", title: "NewEarth ReGenesis ", comment: " This is a great product. I could see the positive effect it was having on my nails and hair pretty quickly. Nails are growing long and strong and I’ve noticed that even though my hair is getting long , I haven’t experienced as much shedding. I’ve got more energy now too, and can skip the morning energy drinks.", rating: "5", verifiedUser: "not-yet", name: " j. almas", email: " gemograd08@yahoo.com" },
            { date: "31-05-23", title: "Fabulous energy ", comment: " If one takes a moment to feel into this formula, one feels the life force moving thru your body. Well done all", rating: "5", verifiedUser: "not-yet", name: "irene R Fox ", email: "lightgypsys@gmail.com " },
            { date: "31-05-23 ", title: " ", comment: " I have not received the order yet.", rating: "5", verifiedUser: "not-yet", name: "Ann Coats ", email: " anncoats01@gmail.com" },
            { date: "31-05-23", title: "Newearth ReGenesis review ", comment: " I just got it, so at this time not able to give a review.  After I use it for about 4months I will be able to let you know more.", rating: "5", verifiedUser: "not-yet", name: "Carol Tanner ", email: " ctanner17421@gmail.com" },
            { date: "31-05-23 ", title: " Regenisis", comment: "feel so much better after starting taking Regenisis, ", rating: "5", verifiedUser: "not-yet", name: " Gitte Barton", email: " gitte.barton@me.com" },
            { date: "31-05-23 ", title: " Packaging disappointing...", comment: "After three days, I think it's too soon to notice any health changes.   I'm not sure how the lid of the container is supposed to work.  I would like to have seen much simpler packaging and less glitz for this quite expensive health product. ", rating: "4", verifiedUser: "not-yet", name: " Judith Taylor", email: " giraudeljudy@gmail.com" },
            { date: "01-06-23 ", title: " ", comment: "I haven’t started yet ", rating: "5", verifiedUser: "not-yet", name: "Deborah Schwank ", email: "dschwank@msn.com " },
            { date: "01-06-23 ", title: " ", comment: " Just got it today.  So excited.  Will let you know in a couple of weeks.", rating: "5", verifiedUser: "not-yet", name: " Linda Obermaier", email: "lobermaier2018@gmail.com " },
            { date: "01-06-23 ", title: " ", comment: "Not arrived yet to my British Columbia, Canada address! ", rating: "5", verifiedUser: "not-yet", name: "Jane Houde ", email: " janehoude2015@gmail.com" },
            { date: "01-06-23 ", title: " ", comment: "Don’t have it as yet ", rating: "5", verifiedUser: "not-yet", name: "Hilda Summers ", email: "lmsummers@shaw.ca " },
            { date: "01-06-23 ", title: " I have not received it yet.", comment: " Place trace.", rating: "5", verifiedUser: "not-yet", name: " Kathryn Gray", email: "cookhouse777@gmail.com " },
            { date: "01-06-23 ", title: " ", comment: " I just got it 2 days ago, so cant comment...however, can you send me info on what it does as I have no clue.   My friend talked me into getting it.  thanks so much, Irena", rating: "5", verifiedUser: "not-yet", name: "Irena Vormittag ", email: " tanassa@hotmail.com" },
            { date: "01-06-23 ", title: " Tastes Great", comment: " Took my first dose upon opening the box.  The tastes is great and I like the dropper design built into the cap..", rating: "5", verifiedUser: "not-yet", name: "Lorna Engel ", email: " potatoefeet@gmail.com" },
            { date: "01-06-23 ", title: " ", comment: "waiting for it to be delivered to s. ecuador ", rating: "5", verifiedUser: "not-yet", name: "Marcia Clanton ", email: " marciamacdoplease@protonmail.com" },
            { date: "02-06-23 ", title: " ", comment: " I haven't received my order yet!.", rating: "5", verifiedUser: "not-yet", name: "Theresa Richau ", email: " thrichau@gmail.com" },
            { date: "02-06-23 ", title: " Great product", comment: " This product seems to be helping me detox. I have not yet seen remarkable changes in my face, i.e. aging, but do believe that will come. Detox is the most important part of health.", rating: "5", verifiedUser: "not-yet", name: "Gina MacFarland ", email: " ginapal@me.com" },
            { date: "03-06-23 ", title: "", comment: "I do not have an opinion at this time", rating: "5", verifiedUser: "not-yet", name: "Donna Paugh", email: "dbkombucha@gmail.com " },
            {
                date: "16-06-23 ", title: "Excellent results so far!", comment: `
            My husband and I, aged 81 and 68 respectively, are both taking NewEarth Regenesis daily and find increased energy and stamina. We also both have better mental focus and are sleeping better. I've been applying it directly to two very ugly scars from infected dog scratches and am seeing the scars lighten and become less noticeable - this is after about 3 weeks of applying it twice a day. We are pleased enough to have placed a monthly subscription to make sure we always have NewEarth Regenesis on hand. Thanks for a great product!
            `, rating: "5", verifiedUser: "ok", name: "Kathleen E Lowyns", email: "dbkombucha@gmail.com "
            },
        ]
    },
    {
        id: 1112, product: "wellness_essentials", review: [
            { date: "12-12-24", title: "", comment: "I have been taking these “new to me” Abeytu Wellness Essentials Capsules 2 at a time, every 4 hours to help with “discomfort” and aches in my back and ribs from a hard fall……..they have saved my fanny big time. Reading the specific ingredients is a Joy! OF COURSE who wouldn’t love to have Organic Blueberry and Shilajit in their body? THANK YOU Abeytu for another incredible plant based product.", rating: "5", verifiedUser: "ok", name: "Judy K ", email: "jodyk8@gmail.com " },
            { date: "06-01-25", title: "", comment: "I have been taking them for over a month now and they truly are helping me with my arthritis pain!", rating: "5", verifiedUser: "ok", name: "ALICIA BRADSHAW", email: "jodyk8@gmail.com " },

        ]
    },


]